@import 'mixins';
@import 'variables';
@import 'kube';
@import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,700);
//general
body{
    font-size: 16px;
}
p,a,button{
    font-family: Open Sans;
}
p{
    line-height: 30px;
}
h1,h2,h4{
    font-family: "proxima-nova",sans-serif;
}
.article-title{
    font-size:1em;
    font-weight:700;
    color:$text-color;
    font-family:Open Sans;
}
.row{
    margin-left:0;
}
#site_wrap{
    position:relative;
}
.container{
    max-width:1400px;
    margin-left:auto;
    margin-right:auto;
    h1{
        @include flex;
        @include flex-center;
        margin-top:30px;
    }
}
.container2{
    width:1400px;
    margin-left:auto;
    margin-right:auto;
}
.section_desc{
    max-width:$maxwidth;
    margin-left:auto;
    margin-right:auto;
    font-size:0.875em;
    color:$text-color;
}
.hidden{
    display: none;
}
.vis_hidden{
    opacity: 0;
}
a{
    color:$text-color;
    text-decoration: none;
}
.dont-boost{
    max-height: 9999px;
}

//navigation
#main_menu_container{
    z-index:20;
    position: fixed;
    top:0;
    left:0;
    right: 0;
    transition: all 0.5s ease-in;
    border-bottom: 1px solid #ccc;
    .row{
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        @include devices_max(768px){
            padding-top: 2px;
            padding-bottom: 2px;
        }
        .logo{
            @include devices(768px,1400px){
                padding-left: 20px;
            }
            height: 95px;
            @include devices(480px,768px){
                height: 45px;
            }
            @include devices_max(480px){
                height: 30px;
            }
        }
        &>.col{
            padding-left: 0;
        }
        .col{
            @include devices_max(768px){
                flex-basis:50%;
            }
        }
        &:first-child{

        }
    }
    #search_input_container{
        position: absolute;
        top:150px;
        left:0;
        right: 0;
        #search_input{
            max-width: 1400px;
            margin-left: auto;
            margin-right: auto;
            text-align: right;
            padding-right: 27px;
            input{
                border-radius: 5px;
            }
            .form-item{
                display: inline-block;
                button{
                    background-color: $green;
                    color: white;
                    &:hover{
                        background-color: $green;
                    }
                }
            }
        }
    }
    #main_menu_social{
        @include flex;
        @include flex-middle;
        margin-bottom:20px;
        margin-top:10px;
        @include devices_max(768px){
            display: none;
        }
        p{
            margin: 0 20px 0 0;
            font-size:0.875em;
        }
        #fb_header,#g_header,#twitter_header{
            width: 45px;
            height: 45px;
            display: block;
            margin-right: 10px;
        }
        #fb_header{
            background:transparent url('/images/assets/group-45.svg') center top no-repeat;
            &:hover{
                background-image: url('/images/assets/footer_fb.svg');
            }
        }
        #twitter_header{
            background:transparent url('/images/assets/group-46.svg') center top no-repeat;
            &:hover{
                background-image: url('/images/assets/footer_twitter.svg');
            }
        }
        #g_header{
            background:transparent url('/images/assets/group-47.svg') center top no-repeat;
            &:hover{
                background-image: url('/images/assets/footer_g+.svg');
            }
        }
        img{
            margin-left:10px;
            &:first-child{
                margin-left:30px;
            }
            &:hover{
                filter:invert(100%);
            }
        }
    }
    #main_menu{
        @include devices_max(1200px){
            display: none;
        }
        ul{
            li{
                display:inline-block;
                a{
                    text-decoration:none;
                    padding-left:10px;
                    padding-right:10px;
                    font-weight: 900;
                    font-family: "proxima-nova",sans-serif;
                    cursor:pointer;
                    &:hover{
                        color:$blue;
                    }
                }
            }
        }
    }
    #burger_menu{
        @include devices_min(1201px){
            display: none;
        }
        @include devices(480px,768px){
            margin-top: 5px;
        }
        i{
            font-size: 1.6125em;
            @include devices_max(480px){
                font-size: 1.3125em;
            }
            margin-right: 15px;
            color:white;
            cursor:pointer;
        }
        .burger_ul{
            position: absolute;
            top:150px;
            @include devices(480px,768px){
                top:60px;
            }
            @include devices_max(480px){
                top:45px;
            }
            right: 0px;
            left: 0px;
            text-align: right;
                        @include devices_max(1200px){
                background: $green;
                text-align: center;
                border:1px solid #ccc;
            }
            li{
                display:block;
                a{
                    display: block;
                    text-decoration:none;
                    font-weight: 900;
                    font-size: 1.25em;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    @include devices_max(480px){
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                    cursor:pointer;
                    color: white;
                    font-family: "proxima-nova",sans-serif;
                    &:hover{
                        color:$text-color;
                    }

                }
            }
        }
        #burger_ul{

            visibility: hidden;

            #products_menu_item_large{
                display: block;
                @include devices_max(768px){
                    display: none;
                }
            }
            #products_menu_item_small{
                display: none;
                @include devices_max(768px){
                    display: block;
                }
                a{
                    cursor: pointer;
                    i{
                        color: white;
                        font-size: 21px;
                    }
                    &:hover{
                        i{
                            color: $text-color;
                        }
                    }
                }
            }
            #burger_social{
                @include devices_min(769px){
                    display: none;
                }
                #email_burger_container{
                    @include flex;
                    @include flex-center;
                    @include flex-middle;
                    p{
                        margin:0;
                        color: white;
                        font-size: 0.875em;
                    }
                }
                #fb_header_burger,#g_header_burger,#twitter_header_burger{
                    width: 45px;
                    height: 45px;
                    @include devices_max(370px){
                        width: 30px;
                        height: 30px;
                    }
                    display: inline-block;
                    margin-right: 10px;
                }
                #fb_header_burger{
                    background:transparent url('/images/assets/group-45.svg') center top no-repeat;
                    &:hover{
                        background-image: url('/images/assets/footer_fb.svg');
                    }
                }
                #twitter_header_burger{
                    background:transparent url('/images/assets/group-46.svg') center top no-repeat;
                    &:hover{
                        background-image: url('/images/assets/footer_twitter.svg');
                    }
                }
                #g_header_burger{
                    background:transparent url('/images/assets/group-47.svg') center top no-repeat;
                    &:hover{
                        background-image: url('/images/assets/footer_g+.svg');
                    }
                }
            }
        }
        #products_ul{
            display: none;
            li{
                a{
                    @media screen and (max-height: 500px){
                        padding-top:5px;
                        padding-bottom: 5px;
                    }
                    cursor: pointer;
                    i{
                        color: white;
                        font-size: 21px;
                    }
                    &:hover{
                        i{
                            color: $text-color;
                        }
                    }
                }
            }
        }
    }
}
#pages_main_menu{
    #main_menu_container{
        padding-bottom:10px;
        @include devices_max(768px){
            padding-bottom:0px;
        }
        background-color: white;
    }
    a{
        color:$text-color;
    }
    #burger_menu{
        i{
            color:$text-color;
        }
        #burger_ul{
            li{
            }
        }
    }
}
#home_main_menu{
    a{
        color:white;
    }
    #main_menu_container{
        border-width: 0px;
    }
    #main_menu_social{
        color:white;
    }
    #burger_menu{
        i{
            color:white;
        }
        #burger_ul{
            @include devices(480px,1200px){
                border:1px solid #ccc;
            }
            @include devices_max(480px){
                border:1px solid #ccc;
            }
        }
    }
}
#burger_search_container{
    z-index:20;
    position: fixed;
    top:160px;
    @include devices(480px,768px){
        top:65px;
    }
    @include devices_max(480px){
        top:50px;
    }
    right:0;
    left:0;
    #burger_input_container{
        width: 70%;
        @include devices_max(570px){
            width: 95%;
        }
        margin-left: auto;
        margin-right: auto;
        input{
            width: 82%;
            @include devices(359px,570px){
                width: 70%;
            }
            @include devices_max(359px){
                width: 65%;
            }
            border-radius: 5px;
        }
        .form-item{
            width: 17%;
            display: inline-block;
            button{
                background-color: $green;
                color: white;
                &:hover{
                    background-color: $green;
                }
            }
        }
    }
}
.fixed_menu{
    position:fixed;
    top:0px;
    left:0px;
    right:0px;
    z-index: 20;
    padding-right: 10px;
    padding-left: 10px;
    padding-top:5px;
    padding-bottom:5px;
    background-color:white;
    border-bottom: 1px solid #ccc;
    a{
        color:$text-color!important;
        &:hover{
            color:$blue!important;
        }
    }
}

//footer
footer{
    #newsletter_container{
        @include devices_min(768px){
            padding:125px 0px 80px 0px;
        }
        @include devices_max(768px){
            padding:20px 0px 20px 0px;
        }
        border-top:1px solid #a0a0a0;
        border-bottom:1px solid #a0a0a0;
        background-color:$footer-color;
        h2{
            color:$green;
        }
        #footer_contact_info{
            @include devices_max(768px){
                text-align: center;
            }
            @include devices_min(769px){
                text-align: left;
            }
            h2{
                margin-bottom:20px;
            }
            p{
                margin-bottom:9px;
                @include devices_max(768px){
                    margin-bottom: 0px;
                }
                color:$text-color;
                font-size:0.875em;
            }
        }
        #footer_social_media{
            margin-top:50px;
            @include flex;
            @include flex-center;
            @include flex-middle;
            @include devices_max(768px){
                margin-top:20px;
            }
            #fb_footer,#g_footer,#twitter_footer{
                width: 50px;
                height:50px;
                display:block;
                margin-right: 5px;
                margin-left: 5px;
            }
            #fb_footer{
            background:transparent url('/images/assets/footer_fb.svg') center top no-repeat;
                &:hover{
                    background-image: url('/images/assets/group-45.svg');
                }
            }
            #twitter_footer{
                background:transparent url('/images/assets/footer_twitter.svg') center top no-repeat;
                &:hover{
                    background-image: url('/images/assets/group-46.svg');
                }
            }
            #g_footer{
                background:transparent url('/images/assets/footer_g+.svg') center top no-repeat;
                &:hover{
                    background-image: url('/images/assets/group-47.svg');
                }
            }
        }
        #footer_newsletter{
            .section_desc{
                max-width:500px;
                margin-bottom:20px;
            }
            input{
                @include devices_max(480px){
                    width:95%;
                    margin-right: 0px;
                }
                @include devices(480px,768px){
                    width:50%;
                    margin-right: 20px;
                }
                @include devices_min(768px){
                    width:70%;
                    margin-right: 20px;
                }
                border-color:#a0a0a0;
                border-radius:25px;
                height:50px;
                @include devices_max(480px){
                    height:40px;
                }
                text-align:center;
            }
            #facebook_logo{
                height:50px;
                width:50px;
                .cls-1{
                    fill:#666;
                }
                @include devices_max(480px){
                    margin-left:-40px;
                    height:40px;
                    width:40px;
                }

            }
            #facebook_logo:hover{
                .cls-1{
                    fill:$blue;
                }
            }
        }
        #footer_product_icons{
            margin-top:100px;
            @include devices_max(768px){
                margin-top:20px;
            }
            img{
                height:35px;
                    @include devices_max(1450px){
                        padding-left: 10px;
                        padding-right: 10px;
                    }
            }
        }
    }
    #copyright_container{
        padding:5px 0px 15px 0px;
        @include devices_max(768px){
            padding-bottom:5px;
        }
        p{
            font-size:0.75em;
            color:$text-color;
            @include devices_max(768px){
                margin-bottom:0px;
            }
            @include devices_min(769px){
                margin-top:30px;
            }
        }
        #footer_logo{
            img{
                height: 60px;
            }
        }
        #footer_logo,#copyright,#designed{
            @include devices_max(768px){
                text-align: center;
            }
        }
        #copyright{
            @include devices_min(769px){
                text-align: left;
            }
        }
        #designed{
            @include devices_min(769px){
                text-align: right;
            }
                @include devices_max(1450px){
                    padding-right: 10px;
                }
        }
    }
}

//homepage
#homepage_main_image{
    z-index:-1;
    height:1080px;
    @include devices(481px,1200px){
        height:600px;
    }
    @include devices_max(480px){
        height:300px;
    }
    @media screen and (max-height: 1100px) and (min-height: 741px){
        height:600px;
    }
    @media screen and (max-height: 740px) {
        height:400px;
    }
    //background:url('/images/homepage/main_banner.jpeg');
    .parallax-window {
        min-height: 1080px;
        @include devices(481px,1200px){
            min-height:600px;
        }
        @include devices_max(480px){
            min-height:300px;
        }
        @media screen and (max-height: 1100px) and (min-height: 741px){
            min-height:600px;
        }
        @media screen and (max-height: 740px) {
            min-height:400px;
        }
        background: transparent;
    }
    .inner_title{
        position:absolute;
        color:white;
        top:650px;
        left:0;
        right:0;
        text-align: center;
        letter-spacing:22px;
        font-size:1.25em;
        @include devices(481px,1200px){
            top:300px;
        }
        @include devices(480px,768px){
            font-size:1em;
            letter-spacing:10px;
        }
        @include devices_max(480px){
            top:130px;
            letter-spacing:16px;
            font-size:1em;
        }
        @media screen and (max-height: 1100px) and (min-height: 741px){
            top:300px;
        }
        @media screen and (max-height: 740px) {
            top:180px;
        }
        p{
            font-family: Arial;
            @include devices_max(480px){
                padding-left: 5px;
                font-size:0.875em;
                letter-spacing:6px;
            }
        }
    }
    .inner_banner{
        position:absolute;
        top:787px;
        @include devices(769px,1200px){
            top:380px;
        }
        @include devices(481px,768px){
            top:400px;
        }
        @include devices_max(480px){
            visibility: hidden;
        }
        @media screen and (max-height: 1100px) and (min-height: 741px){
            top:400px;
        }
        @media screen and (max-height: 740px) {
            top:250px;
        }
        left:0;
        right:0;
        text-align: center;
        padding-right: 10px;
        padding-left: 10px;
    }
}

.homepage_section_title{
    color:$green;
}

#homepage_secondary_image{
    margin-top:30px;
    @include devices_max(768px){
        display: none;
    }
}

#homepage_about_us_row{
    margin-top: -550px;
    @include devices(768px,1200px){
        margin-top: -500px;
    }
    @include devices_max(768px){
        margin-top: 0px;
    }
    #homepage_about_us_container{
        width:600px;
        @include devices_max(768px){
            width:100%;
        }
        text-align: center;
        background-color: white;
        padding:40px 60px 80px 60px;
        @include devices_max(768px){
            padding:20px 0px 20px 0px;
        }
        @include devices(768px,900px){
            padding:40px 60px 40px 60px;
        }
        h1{
            margin-bottom:50px;
            @include devices_max(768px){
                margin-bottom:30px;
            }
        }
        p{
            padding-left: 30px;
            padding-right: 30px;
            @include devices_max(768px){
                padding-left: 10px;
                padding-right: 10px;
            }
        }
        img{
            margin-top:30px;
        }
    }
}

#homepage_products_container{
    &>p{
        padding-right: 10px;
        padding-left: 10px;
    }
    &>.row{
        padding-right: 10px;
        padding-left: 10px;
        .col{
            margin-top: 50px;
            @include flex;
            @include flex-center;
            @include flex-middle;
            @include devices_max(480px){
                margin-top: 10px;
                justify-content: flex-start;
            }
            @include devices_min(768px){
                flex-basis:20%;
            }
            @include devices(480px,768px){
                flex-basis:32%;
            }
            @include devices_max(530px){
                flex-basis:50%;
            }
            flex-wrap:wrap;
            p{
                font-family: "proxima-nova",sans-serif;
                margin-top:10px;
                font-weight: 900;
                color:$text-color;
                padding:10px 25px 10px 25px;
                @include devices_max(480px){
                    line-height: 1em;
                }
            }
        }
        .product_container{
            width:220px;
            height:220px;
            @include devices(340px,480px){
                width:150px;
                height:150px;
            }
            @include devices_max(340px){
                width:130px;
                height:140px;
            }
            border:2px dashed #ccc;
            border-radius:25px;
            @include flex;
            @include flex-center;
            @include flex-middle;
        }
    }
}

#homepage_process_container{
    margin-top:110px;
    #numbering_container{
        img{
            width: 1230px;
            @include devices(769px,1380px){
                width:90%;
            }
            @include devices_max(768px){
                width:90%;
            }
        }
    }
    &>p{
        @include devices_max(1450px){
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    #homepage_process_stages_container{
        &>.row{
            @include devices_max(768px){
                padding-left: 10px;
                padding-right: 10px;
            }
            .col{
            padding-bottom:30px;
            padding-top:30px;
            @include devices_min(769px){
                @include flex;
                @include flex-center;
                @include flex-bottom;
            }
            @include devices_max(768px){
                @include flex;
                @include flex-center;
                padding-bottom:15px;
                padding-top:15px;
            }
            border-right:2px dashed #ccc;
            @include devices_max(980px){
                border-right:0px dashed #ccc;
            }

            padding-left:0;
            &:last-child{
                border-right:0px;
            }
            @include devices_max(768px){
                flex-basis:0%;
            }
            }
            .process_img{
                transition:opacity 1s ease;
            }
            #process_img_1{
                height: 70px;
                @include devices(481px,768px){
                    height: 40px;
                }
                @include devices_max(480px){
                    height: 30px;
                }
            }
            #process_img_2{
                height: 70px;
                @include devices(481px,768px){
                    height: 40px;
                }
                @include devices_max(480px){
                    height: 30px;
                }
                transition-delay: 0.25s;
            }
            #process_img_3{
                height: 100px;
                @include devices(481px,768px){
                    height: 50px;
                }
                @include devices_max(480px){
                    height: 35px;
                }
                transition-delay: 0.5s;
            }
            #process_img_4{
                height: 95px;
                @include devices(481px,768px){
                    height: 50px;
                }
                @include devices_max(480px){
                    height: 35px;
                }
                transition-delay: 1s;
            }
            #process_img_5{
                height: 85px;
                @include devices(481px,768px){
                    height: 45px;
                }
                @include devices_max(480px){
                    height: 35px;
                }
                transition-delay: 1.25s;
            }
            #process_img_6{
                height: 85px;
                @include devices(481px,768px){
                    height: 45px;
                }
                @include devices_max(480px){
                    height: 30px;
                }
                transition-delay: 1.5s;
            }
            #process_img_7{
                height: 65px;
                @include devices(481px,768px){
                    height: 35px;
                }
                @include devices_max(480px){
                    height: 30px;
                }
                transition-delay: 1.75s;
            }
        }
        .process_titles{
            .col{
                border-right:0px dashed #ccc;
                p{
                    margin:0px;
                    color: $text-color;
                    @include devices(768px,960px){
                        font-size: 0.875em;
                    }
                    @include devices(580px,768px){
                        font-size: 0.75em;
                    }
                    @include devices_max(580px){
                        display: none;
                    }
                }
            }
        }
    }
    #freezing_iqf_container{
        max-width:$maxwidth;
        @include devices(480px,768px){
            max-width:85%;
        }
        @include devices_max(480px){
            max-width:95%;
        }
        margin-right:auto;
        margin-left:auto;
        margin-top:80px;
        border-top:2px solid $blue;
        border-bottom:2px solid $blue;
        padding: 22px 0px 22px 0px;
        .section_desc{
            margin-bottom:0px;
        }
        img{
            height: 170px;
            @include devices_max(480px){
                height: 120px;
            }
        }
    }
    #numbering_container{
        margin-bottom:40px;
        margin-top:40px;
        @include devices_max(768px){
            margin-bottom:0px;
        }
    }
}

#homepage_banners_container{
    color: white;
    margin-top:110px;
    @include devices_max(768px){
        margin-top:30px;
    }
    .banners_text_container{
        padding:50px 100px 50px 60px;
        @include devices_max(600px){
            padding-left: 20px;
            padding-right: 20px;
        }
        h2,h4{
            color: white;
        }
        h2{
            margin-bottom:55px;
            @include devices_max(480px){
                margin-bottom:30px;
            }
        }
        ul{
            li{
                font-size:1.125em;
                margin-top:30px;
                @include devices_max(400px){
                    margin-top:10px;
                }
                font-family: Open Sans;
            }
            li:nth-child(1){
                margin-top:0px;
            }
        }
        #first_banner,#second_banner{
            margin-left: 41%;
            @include devices(1400px,1700px){
                margin-left: 39%;
            }
            @include devices_max(1400px){
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    .first_banner{
        background:url('/images/homepage/equipment_banner.jpeg');
        background-repeat:no-repeat;
        background-size:cover;
    }
    .second_banner{
        background:url('/images/homepage/elladikamas_banner.jpeg');
        background-repeat:no-repeat;
        background-size:cover;
    }
}

#homepage_quality_container{
    padding-bottom:90px;
    @include devices_max(768px){
       padding-bottom:20px;
    }
    p{
        @include devices_max(768px){
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .col{
        @include flex;
        @include flex-center;
        @include flex-middle;
        position: relative;
        padding-bottom: 60px;
        @include devices_max(768px){
            padding-bottom: 30px;
        }
        padding-left: 0;
    }
    .article-title{
        position: absolute;
        bottom:0;
        left:0;
        right: 0;
        text-align: center;

        @include devices_max(768px){
            margin-top:20px;
        }
    }
    .homepage_section_title{
        margin-top:80px;
        @include devices_max(768px){
            margin-top:20px;
        }
    }
    .section_desc{
        margin-bottom:100px;
        @include devices_max(768px){
            margin-bottom:20px;
        }
    }
}

//about page
#about-sidebar {
    @include flex-fixed-width(350px);
    margin-right: 130px;
    @include devices_max(1200px){
        margin-right: 40px;
    }
    @include devices_max(1400px){
        padding-left: 10px;
        padding-right: 10px;
    }
    h1{
        display: block;
        text-align: left;
        color: $blue;
        font-weight: 900;
        padding-bottom: 10px;
        border-bottom: 1px solid #a0a0a0;
    }
    ul{
        margin-left:15px;
        li{
            border-bottom:1px dashed #ccc;
            padding: 10px;
            a{
                color:$text-color;
                font-size: 1.125em;
                font-weight: 900;
                font-family: "proxima-nova",sans-serif;
                &:hover{
                    color:$green;
                }
            }
        }
    }
}
#about_us{
    padding-bottom:100px;
    margin-top:175px;
    @include devices(768px,1200px){
        margin-top:195px;
    }
    @include devices_max(768px){
        padding-bottom:20px;
        margin-top:70px;
    }
    border-bottom: 1px dashed #ccc;
    #about_page_text_container{
        padding-top: 50px;
        padding-right:100px;
        @include devices_max(1400px){
            padding-left: 10px;
            padding-right: 10px;
        }
        h2{
            color: $green;
            font-weight: 300;
        }
        p{
            color: $text-color;
        }
    }
    #about_us_cover_img{
        width:100%;
        height: 330px;
        background:url('/images/homepage/main_banner.jpeg');
        background-position: 0% 30%;
    }
}

//products
#products-sidebar {
    @include devices_max(768px){
        display: none;
    }
    @include flex-fixed-width(350px);
    @include devices(768px,1000px){
        @include flex-fixed-width(300px);
    }
    margin-right: 130px;
    @include devices_max(1400px){
        padding-left: 40px;
        padding-right: 20px;
    }
    @include devices(850px,1400px){
        margin-right: 40px;
    }
    @include devices_max(850px){
        margin-right: 0px;
    }
    h1{
        display: block;
        text-align: left;
        color: $blue;
        font-weight: 900;
        padding-bottom: 10px;
        border-bottom: 1px solid #a0a0a0;
        @include devices(768px,1400px){
            font-size:34px;
        }
    }
    ul{
        margin-left:0;
        .sidebar-li-container{
            border-bottom:1px dashed #ccc;
            @include flex;
            @include flex-middle;
            padding:10px;
            img{
                width:40px;
                @include devices(768px,1400px){
                    width:28px;
                }
            }
            li{
                margin-left: 30px;
                a{
                    color:$text-color;
                    font-size: 1.125em;
                    font-weight: 900;
                    font-family: "proxima-nova",sans-serif;
                    &:hover{
                        color:$green;
                    }
                }
            }
        }
    }
}

#product_details_container{
    padding-right:100px;
    padding-top:34px;
    @include devices_max(1400px){
        padding-left: 40px;
        padding-right: 40px;
    }
    @include devices_max(768px){
        padding-top:20px;
        padding-left: 20px;
        padding-right: 20px;
    }
    h2{
        @include flex;
        @include flex-middle;
        color: $green;
        font-weight: 300;
        margin-bottom: 5px;
        img{
            height: 53px;
            margin-right:25px;
        }
    }
    p{
        color: $text-color;
    }
    .single_product_banner{
        width: 100%;
        height: 300px;
        margin-bottom: 20px;
    }
}

//contact
#contact_container{
    padding-top:70px;
    @include devices_max(480px){
        padding-top:10px;
    }
    h1{
        color: $green;
        margin-bottom: 40px;
    }
    #contact_form{
        max-width: 900px;
        margin-right: auto;
        margin-left: auto;
        padding-bottom: 60px;
        padding-right: 10px;
        padding-left: 10px;
        .form-item{
            margin-bottom: 23px;
            input::placeholder{
                font-weight:900;
                font-family: Open Sans;
            }
        }
        button{
            float: right;
            background-color: $green;
            color: white;
            font-weight: 900;
        }
        .col:first-child{
            @include devices_min(769px){
                padding-right: 5px;
            }
        }
        .col:last-child{
            @include devices_min(769px){
                padding-left: 5px;
            }
        }
        textarea{
            height: 230px;
        }
    }

}
#map_container{
    margin-top:151px;
    @include devices(768px,1200px){
        margin-top:171px;
    }
    @include devices_max(768px){
        margin-top:103px;
    }
    iframe{
        width: 100%;
        height: 650px;
        @include devices(480px,768px){
            height: 500px;
        }
        @include devices_max(480px){
            height: 300px;
        }
    }
}
